.notice-container {
  background-color: #f8f9fa;
  padding: 2rem 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.notice-header {
  text-align: center;
  margin-bottom: 2rem;
}

.notice-title {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.notice-title::after {
  content: '';
  position: absolute;
  width: 80px;
  height: 4px;
  background-color: #da7426;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.notice-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
  padding: 0 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.notice-card {
  background-color: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-top: 4px solid #da7426;
}

.notice-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.branch-title {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 1.2rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #eee;
}

.branch-list {
  list-style-type: none;
  padding: 0;
}

.branch-list li {
  font-size: 1rem;
  margin-bottom: 0.8rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid #f5f5f5;
  color: #555;
}

.notice-footer {
  text-align: center;
  margin-top: 3rem;
}

.welcome-message {
  font-size: 2rem;
  color: #2c3e50;
  position: relative;
  display: inline-block;
}

.welcome-message::after {
  content: '';
  position: absolute;
  width: 100px;
  height: 4px;
  background-color: #da7426;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .notice-title {
    font-size: 2rem;
  }
  
  .notice-grid {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }
  
  .welcome-message {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .notice-title {
    font-size: 1.8rem;
  }
  
  .branch-title {
    font-size: 1.3rem;
  }
  
  .branch-list li {
    font-size: 0.9rem;
  }
}