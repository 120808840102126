/* Gallery.css */
.gallery-page {
  background-color: #f8f9fa;
  min-height: 100vh;
}

/* Hero Section */
.hero-section {
  background: linear-gradient(135deg, #2c3e50 0%, #da7426 100%);
  padding: 6rem 0;
  position: relative;
}

.hero-overlay {
  position: relative;
  z-index: 1;
}

.hero-title {
  color: white;
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.hero-subtitle {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.25rem;
}

.hero-divider {
  width: 80px;
  height: 4px;
  background-color: #ffffff;
  margin: 2rem auto;
}

/* Gallery Section */
.gallery-section {
  padding: 3rem 0;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #2c3e50;
}

.section-title span {
  color: #da7426;
}

.section-divider {
  width: 100px;
  height: 4px;
  background-color: #da7426;
  margin: 1rem auto;
}

.gallery-card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 450px; /* Increased width */
  margin: 0 auto; /* Center the card in its column */
}

.gallery-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.gallery-image-container {
  background-color: #f1f1f1;
  overflow: hidden;
  height: 300px; /* Increased height */
}

.gallery-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-card:hover .gallery-image-container img {
  transform: scale(1.05);
}

#batch-dropdown {
  background-color: #da7426;
  border: none;
  padding: 0.5rem 1.5rem;
}

.btn-outline-orange {
  color: #da7426;
  border-color: #da7426;
}

.btn-outline-orange:hover {
  background-color: #da7426;
  color: white;
  border-color: #da7426;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-title {
    font-size: 2.5rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .gallery-card {
    max-width: 300px;
  }

  .gallery-image-container {
    height: 250px;
  }
}

@media (max-width: 576px) {
  .hero-section {
    padding: 4rem 0;
  }

  .gallery-section {
    padding: 2rem 0;
  }

  .gallery-card {
    max-width: 100%;
  }

  .gallery-image-container {
    height: 200px;
  }
}