.sponsor-page {
  background-color: #f8f9fa;
  font-family: 'Rajdhani', sans-serif;
}

.sponsor-card {
  background: white;
  border-radius: 10px;
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  color: #333;
  border-top: 4px solid #da7426;
}

.sponsor-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(218, 116, 38, 0.1) !important;
}

.sponsor-card h3 {
  font-weight: 700;
  letter-spacing: 1px;
  color: #333;
}

.sponsor-card ul {
  text-align: left;
  padding-left: 20px;
}

.sponsor-card ul li {
  margin-bottom: 10px;
  position: relative;
  line-height: 1.5;
}

.sponsor-card ul li::before {
  content: "•";
  color: #da7426;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

.btn-outline:hover {
  background-color: #da7426;
  color: white;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .sponsor-card {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .sponsor-page h2 {
    font-size: 2.2rem;
  }
  
  .sponsor-page h3 {
    font-size: 1.8rem;
  }
}

@media (max-width: 576px) {
  .sponsor-card {
    padding: 20px 15px;
  }
  
  .sponsor-page h2 {
    font-size: 1.8rem;
  }
  
  .sponsor-page h3 {
    font-size: 1.5rem;
  }
}