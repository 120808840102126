/* Gallery Styles */
.gallery-container {
  min-height: 100vh;
  padding: 2rem 0;
}

.member-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 0.75rem;
  overflow: hidden;
  border: none;
}

.member-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.member-image-container {
  height: 250px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
}

.member-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.social-links a {
  transition: transform 0.2s ease;
}

.social-links a:hover {
  transform: scale(1.1);
}

#batch-dropdown {
  background-color: #da7426;
  border: none;
  padding: 0.5rem 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-menu {
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #da7426;
}